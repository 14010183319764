<script lang="ts">
  import UDoughnut from '$lib/components/charts/UDoughnut.svelte';
  import { autorun } from 'mobx';
  import { onDestroy } from 'svelte';
  import type { AbstractUserStore } from 'shared/src/user/abstract.user.store';

  export let userStore: AbstractUserStore;
  let clazz: string = '';
  export { clazz as class };

  let props: {
    gradeToDate: number;
    gradeToDateComponents: {
      label: string;
      value: number;
    }[];
    outOf: number;
  };
  const destroyer = autorun(() => {
    props = {
      gradeToDate: userStore.gradeToDate,
      gradeToDateComponents: userStore.gradeToDateComponents.map((component) => ({
        label: component.label,
        value: component.value,
      })),
      outOf: userStore.gradeToDateComponents.length * 100,
    };
  });
  onDestroy(destroyer);
</script>

<UDoughnut
  class={clazz}
  showLabels={true}
  labels={props.gradeToDateComponents.map((c) => c.label)}
  total={props.gradeToDate}
  data={props.gradeToDateComponents.map((c) => c.value)}
  outOf={100 * props.gradeToDateComponents.length}
  totalLabel="Grade to Date"
/>
